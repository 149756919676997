// local
// const BASE_URL = "http://192.168.21.19:5001/node-api/";
// const DEFAULT_KEY = 'fe8613f5-dae3-43dd-9163-6adf24c77fee'

// demo
const BASE_URL = "https://admin.cesar.iossmlm.com/node-api/";
const DEFAULT_KEY = 'fe8613f5-dae3-43dd-9163-6adf24c77fee'

const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
