import API from "../../api/api";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const ReportService = {
    getCouponReport: async (page, itemsPerPage) => {
        const response = await callApi(`/coupon-report?page=${page}&perPage=${itemsPerPage}`);
        return response
    },
   
}

