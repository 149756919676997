import { ReportService } from "../../services/report/report";

export const CouponReport = async (page, limit) => {
  try {
    const response = await ReportService.getCouponReport(page, limit);
    return response;
  } catch (error) {
    console.log(error.message);
  }
};
