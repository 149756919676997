import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getLastPage } from '../../utils/getLastPage';
import { ApiHook } from '../../hooks/apiHook';
import TableMain from '../../components/Common/table/TableMain';

export default function MainCouponReport() {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    
    const report = ApiHook.CallCouponReport(currentPage, itemsPerPage)
    const headers = ["coupon", "discount", "coupon_status"];
    const lastPage = getLastPage(itemsPerPage,report?.data?.totalCount);

    return (
        <>
         <div className="page_head_top">{t("coupon_report")}</div>
         <div className="ewallet_table_section">
            <div className="ewallet_table_section_cnt">
                <div className="table-responsive min-hieght-table">
                    <TableMain
                        headers={headers}
                        data={report?.data?.data}
                        startPage={1}
                        currentPage={currentPage}
                        totalPages={lastPage}
                        type={"coupon-report"}
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            </div>
        </div>
        </>
      
    )
}
